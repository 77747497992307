import { useInitialFocus } from '@coop/hooks';

import { Button } from '../Button';
import type { BaseConfirmProps } from './BaseConfirm';
import BaseConfirm from './BaseConfirm';

interface ConfirmAlertProps extends BaseConfirmProps {
    onConfirm: () => void;
    onCancel?: () => void;
    cancelButtonText?: string;
    /**
     * Text for the confirm button
     */
    confirmButtonText?: string;
}

/**
 * Confirm modal with two red buttons, one for confirm and one for cancel.
 * Alert or warning message where the user is about to make an important or destructive choice.
 */
const ConfirmAlert = (props: ConfirmAlertProps) => {
    const confirmButtonTextFinal = props.confirmButtonText || 'Okej, då vet jag';
    const cancelButtonTextFinal = props.cancelButtonText || 'Avbryt';

    const leastDestructiveActionRef = useInitialFocus(props.show);

    return (
        <BaseConfirm
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            buttonSlot={
                <>
                    <Button
                        ref={leastDestructiveActionRef}
                        theme="redLight"
                        onClick={() => {
                            props.onCancel?.();
                            props.close();
                        }}
                    >
                        {cancelButtonTextFinal}
                    </Button>
                    <Button theme="red" onClick={props.onConfirm}>
                        {confirmButtonTextFinal}
                    </Button>
                </>
            }
        />
    );
};

export default ConfirmAlert;
