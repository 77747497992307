/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
import { useInitialFocus } from '@coop/hooks';
import type * as React from 'react';

import { Button } from '../Button';
import type { BaseConfirmProps } from './BaseConfirm';
import BaseConfirm from './BaseConfirm';

interface ConfirmProps extends BaseConfirmProps {
    onConfirm: () => void;
    onCancel?: () => void;
    cancelButtonText?: string;
    /**
     * Text for the confirm button
     */
    confirmButtonText?: string;

    cancelButtonProps?: React.ComponentProps<typeof Button>;
    confirmButtonProps?: React.ComponentProps<typeof Button>;
}

/**
 * Confirm modal with two buttons, one for confirm and one for cancel.
 * Useful when we want the user to confirm or cancel a specific choice.
 */
const Confirm = (props: ConfirmProps) => {
    const confirmButtonTextFinal = props.confirmButtonText || 'Ja';
    const cancelButtonTextFinal = props.cancelButtonText || 'Avbryt';

    const leastDestructiveActionRef = useInitialFocus(props.show);

    return (
        <BaseConfirm
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            buttonSlot={
                <>
                    <Button
                        ref={leastDestructiveActionRef}
                        theme="light"
                        onClick={() => {
                            props.onCancel?.();
                            props.close();
                        }}
                        children={cancelButtonTextFinal}
                        {...props.cancelButtonProps}
                    />
                    <Button
                        theme="primary"
                        onClick={props.onConfirm}
                        children={confirmButtonTextFinal}
                        {...props.confirmButtonProps}
                    />
                </>
            }
        />
    );
};

export default Confirm;
