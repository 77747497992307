/* eslint-disable react/jsx-props-no-spreading */
import type { ComponentType, FC } from 'react';

import Portal from '../components/atoms/Portal';
import useComponentLoading from '../hooks/useComponentLoading';
import type { ComponentModule } from './componentLoader';
import { isMountableReactComponent, mountableReactComponents } from './mountableReactComponents';
import {
    buildReactComponentSelectorById,
    getReactBlocksDataFromDom,
    getReactComponentData,
} from './reactMounting';

interface MountedComponentDOMData<TProps extends {} = {}> {
    id: string;
    name: string;
    html: HTMLElement;
    Component: () => Promise<ComponentModule<TProps>>;
    serverProps: TProps;
}

const getDOMAttachedReactComponent = (cmpHtml: HTMLElement) => {
    const { name: cmpName, props: cmpServerProps, id: cmpId } = getReactComponentData(cmpHtml);

    const Component = isMountableReactComponent(cmpName) && mountableReactComponents[cmpName];
    if (!Component) {
        // eslint-disable-next-line no-console
        console.error(
            `Cannot mount component: ${cmpName}. Check the spelling of a component's HTML anchor and make sure it's setup in mountableReactComponents module`,
        );
        return null;
    }

    return {
        id: cmpId,
        html: cmpHtml,
        name: cmpName,
        Component,
        serverProps: cmpServerProps,
    } as MountedComponentDOMData;
};

const getComponentsFromDOM = () => {
    const componentsData = getReactBlocksDataFromDom();

    const componentSelectors = componentsData
        .map((item) => buildReactComponentSelectorById(item.id))
        .join(', ');

    if (!componentSelectors) return [];

    return Array.from(document.querySelectorAll<HTMLElement>(componentSelectors))
        .map((item) => item && getDOMAttachedReactComponent(item))
        .filter(Boolean);
};

const ComponentLoader = (props: MountedComponentDOMData) => {
    const Component = useComponentLoading(props.name, props.Component);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {Component && (
                <Portal domNode={props.html}>
                    <MountedComponent cmp={{ ...props, Component }} />
                </Portal>
            )}
        </>
    );
};

/** Mounts components that were found in DOM */
const ReactComponentLoader: FC<React.PropsWithChildren<unknown>> = () => {
    const MountableComponents = getComponentsFromDOM();

    return (
        <>
            {MountableComponents.map((cmp: MountedComponentDOMData) => {
                return <ComponentLoader key={cmp.id} {...cmp} />;
            })}
        </>
    );
};

interface MountedComponentProps<TProps extends {} = {}>
    extends Omit<MountedComponentDOMData, 'Component'> {
    id: string;
    Component: ComponentType<React.PropsWithChildren<TProps>>;
    serverProps: TProps;
}

const MountedComponent = <TProps extends {} = {}>({
    cmp,
}: {
    cmp: MountedComponentProps<TProps>;
}) => {
    return <cmp.Component {...cmp.serverProps} />;
};

export default ReactComponentLoader;
