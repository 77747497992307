import type { FC } from 'react';

import EpiComponentLoader from '../../episerver/components/base/EpiComponentLoader';
import Portal from '../components/atoms/Portal';
import { getReactComponentData } from './reactMounting';

const getEpiComponentData = (element: HTMLElement) => {
    const component = getReactComponentData<PageViewSettingsIContent>(element);
    if (component.error) {
        // eslint-disable-next-line no-console
        console.error(component.error);
        return null;
    }

    return component;
};

export const getEpiComponentsFromDOM = () => {
    const epiComponentData = Array.from(
        document.querySelectorAll<HTMLElement>('.js-reactEpiComponent'),
    )
        .map(getEpiComponentData)
        .filter(Boolean);

    return epiComponentData;
};

const ReactEpiComponentLoader: FC<React.PropsWithChildren<unknown>> = () => {
    const epiComponents = getEpiComponentsFromDOM();

    return (
        <>
            {epiComponents.map((cmp) => {
                return (
                    <EpiComponentLoader key={cmp.id} expandedValue={cmp.props}>
                        {(EpiComponent, epiComponentProps) => (
                            <Portal domNode={cmp.html}>
                                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                <EpiComponent {...epiComponentProps} />
                            </Portal>
                        )}
                    </EpiComponentLoader>
                );
            })}
        </>
    );
};

export default ReactEpiComponentLoader;
