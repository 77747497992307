/* eslint-disable react/jsx-props-no-spreading */
import { Slot, Slottable } from '@radix-ui/react-slot';
import classNames from 'classnames';
import type { HTMLAttributes } from 'react';
import * as React from 'react';
import { pick } from 'underscore';

import { Loader } from '../Loader';
import styles from './Button.module.scss';
import type { BaseButtonProps, ButtonProps } from './Button.types';
import { getSpinnerSizeForButtonSize } from './Button.utils';

type AllOptionalKeys<T> = { [K in keyof T]-?: undefined extends T[K] ? K : never }[keyof T];
type AllNonOptionalKeys<T> = { [K in keyof T]-?: undefined extends T[K] ? never : K }[keyof T];

type OptionalToMaybeUndefined<T> = { [K in AllOptionalKeys<T>]: T[K] | undefined } & {
    [K in AllNonOptionalKeys<T>]: T[K];
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ size = 40, ...props }, ref) => {
    const classes = classNames(
        props.className,
        styles.Button,
        props.asIcon && styles['Button--icon'],
        props.disabled && styles['Button--disabled'],
        props.fullWidth && styles['Button--fullWidth'],
        props.isActive && styles['is-active'],
        props.isLoading && styles['Button--loading'],
        styles[`Button--size${size}`],
        styles[`Button--${props.theme || 'primary'}`],
    );

    // Convert all optional keys to required but with undefined as a possible value, to make it typesafe when a prop is added to BaseButtonProps without changing customProps below
    const customProps: OptionalToMaybeUndefined<BaseButtonProps> = {
        theme: props.theme,
        size,
        testSelector: props.testSelector,
        isLoading: props.isLoading,
        asChild: props.asChild,
        asIcon: props.asIcon,
        rightSlot: props.rightSlot,
        isActive: props.isActive,
        fullWidth: props.fullWidth,
        className: props.className,
        loadingText: props.loadingText,
    };

    const keysNotFromCustomProps = Object.keys(props).filter(
        (key) => !Object.keys(customProps).includes(key),
    );

    // Get only props that are not in custom props - will essentailly become native HTMLElement's attributes in runtime
    // If we would spread props to a Slot component, it would throw a warnining in console about props that are not available as native attributes in HTML tag
    const baseButtonProps = pick(props, keysNotFromCustomProps);

    const useHtmlButtonElement = !props.asChild;

    const isDisabled = props.isLoading || (useHtmlButtonElement && props.disabled);
    const disabledOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const buttonClick = isDisabled ? disabledOnClick : baseButtonProps.onClick;

    const htmlButtonOnlyProps = useHtmlButtonElement
        ? ({
              disabled: false, // accessibility, do not use html disable as it makes button unfocusable
              'aria-disabled': isDisabled,
              onClick: buttonClick,
              type: baseButtonProps.type || 'button',
          } as Partial<HTMLAttributes<HTMLButtonElement>>)
        : {};

    const Component = useHtmlButtonElement ? 'button' : Slot;

    if (props.isLoading) {
        return (
            <Component
                {...baseButtonProps}
                ref={ref}
                className={classes}
                data-test={customProps.testSelector}
                {...htmlButtonOnlyProps}
                onClick={
                    customProps.isLoading
                        ? (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                          }
                        : htmlButtonOnlyProps.onClick
                }
            >
                <div className={styles['Button-loading']}>
                    {props.loadingText || 'Laddar'}
                    <Loader
                        ariaHidden
                        type="spinner"
                        theme="currentColor"
                        size={getSpinnerSizeForButtonSize(size)}
                    />
                </div>
            </Component>
        );
    }

    return (
        <Component
            {...baseButtonProps}
            ref={ref}
            className={classes}
            data-test={customProps.testSelector}
            {...htmlButtonOnlyProps}
        >
            {/* TODO keep an eye on this: https://github.com/radix-ui/primitives/issues/1825 and modify when its available */}
            <Slottable>{props.children}</Slottable>

            {props.rightSlot && React.isValidElement(props.rightSlot)
                ? React.cloneElement(props.rightSlot, {
                      className: classNames(
                          props.rightSlot.props?.className,
                          styles['Button-rightSlot'],
                      ),
                      'aria-hidden': true,
                  } as React.HTMLAttributes<HTMLElement>)
                : props.rightSlot}
        </Component>
    );
});

export default Button;
