/* eslint-disable @typescript-eslint/no-explicit-any */
export type AppConfig = RecursiveReadonly<{
    coopSettings: CoopSettings;
    /**
     * @deprecated Use Redux hooks to access user settings.
     * The idea behind using hooks instead is to allow Coop.se to be self-hosted as a pure SPA (i.e. for end-to-end testing purposes on CI) without the need for a global object that is injected by backend (or even without ASP.NET MVC backend at all).
     * It also makes sure that user data can be mocked in Storybook stories and component tests.
     */
    coopUserSettings: CoopUserSettings;
}>;

export const appConfig: AppConfig = {
    coopSettings: (window as any).coopSettings as CoopSettings,
    coopUserSettings: (window as any).coopUserSettings,
};
