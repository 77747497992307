/* eslint-disable react/jsx-props-no-spreading */

import EpiComponentLoader from '../../episerver/components/base/EpiComponentLoader';
import Portal from '../components/atoms/Portal';
import { getReactComponentData } from './reactMounting';

const getEpiComponent = (element: HTMLElement) => {
    const component = getReactComponentData<PageViewSettingsIContent>(element);
    if (component.error) {
        // eslint-disable-next-line no-console
        console.error(component.error);
        return null;
    }

    return component;
};
const getEpiPageComponents = () => {
    const epiComponentData = Array.from(
        document.querySelectorAll<HTMLElement>('.js-reactEpiPageComponent'),
    )
        .map(getEpiComponent)
        .filter(Boolean);

    return epiComponentData;
};

const ReactEpiPageLoader = () => {
    const components = getEpiPageComponents();

    return (
        <>
            {components.map((component) => (
                <EpiComponentLoader
                    key={component.id}
                    expandedValue={component.props}
                    options={{ isContentAreaItem: component.isPartialPage }}
                >
                    {(EpiComponent, epiComponentProps) => (
                        <Portal domNode={component.html}>
                            <EpiComponent {...epiComponentProps} />
                        </Portal>
                    )}
                </EpiComponentLoader>
            ))}
        </>
    );
};

export default ReactEpiPageLoader;
