import type { HybrisCartEntry } from '../../models/hybris/hybrisCart';
import type {
    PaymentCode,
    SubscriptionFrequency,
} from '../../store/structureDefinitions/checkoutState';
import type { InvoiceAddress, UserAddress } from '../../store/structureDefinitions/userState';
import getDirectHybrisAxiosClient from '../clients/directHybrisAxiosClient';
import hybrisAxiosClient from '../clients/hybrisAxiosClient';
import type {
    ApiAddMultipleRecipesRequest,
    ApiAddMultipleRecipesResponse,
} from '../recipe/recipeModels';
import type { ApiModifyCartItemRequest } from './requests/cartRequests';
import type {
    ApiCart,
    ApiCreatePunchoutReponse,
    ApiEditPaymentResponse,
    ApiGetPaymentMethodsResponse,
    ApiHmpPayment,
    ApiIntrumPayment,
    ApiKobePayment,
    ApiStoreDeliveryOptions,
    ApiSubstituteProductResponse,
    ApiValidationCartResponse,
} from './responses/cartResponses';
import validateCartErrorInterceptor from './validateCartErrorInterceptor';

const fetchCart = async () => {
    const response = await hybrisAxiosClient.get<ApiCart>(`/carts/current`);
    return response.data;
};

const fetchCartSimple = async () => {
    const response = await hybrisAxiosClient.get<ApiCart>(`/carts/current/simple`);
    return response.data;
};

const emptyCart = () => hybrisAxiosClient.delete(`/carts/current/empty`);

const buyMultipleRecipes = async (request: ApiAddMultipleRecipesRequest) => {
    const response = await hybrisAxiosClient.post<ApiAddMultipleRecipesResponse>(
        `carts/current/recipes`,
        request,
    );
    return response.data;
};

const changeCartItemVariant = (request: ApiModifyCartItemRequest): Promise<void> =>
    hybrisAxiosClient.put(`carts/current/items`, request);

const changeRecipePortions = (recipeId: string, portions: number) =>
    hybrisAxiosClient.put(`carts/current/recipe/${recipeId}/portions/${portions}`);

const removeRecipe = async (recipeId: string): Promise<void> =>
    hybrisAxiosClient.delete(`carts/current/recipe/${recipeId}`);

const getProductSubstitutes = async (rowId: string) => {
    const substituteProducts = await hybrisAxiosClient.get<{ id: string }[]>(
        `/carts/current/recipe/${rowId}`,
    );
    return substituteProducts.data;
};

const substituteProduct = async (
    rowId: string,
    productId: number | string,
): Promise<ApiSubstituteProductResponse> => {
    const response = await hybrisAxiosClient.post<ApiSubstituteProductResponse>(
        `/carts/current/recipe/${encodeURI(rowId)}/${productId}`,
    );

    return response.data;
};

const applyVoucher = async (voucherId: string): Promise<void> => {
    await hybrisAxiosClient.post(`/carts/current/vouchers/${encodeURI(voucherId)}`);
};

const removeVoucher = async (voucherId: string): Promise<void> => {
    await hybrisAxiosClient.delete(`/carts/current/vouchers/${encodeURI(voucherId)}`);
};

const getDeliveryAddressList = async (): Promise<UserAddress[]> => {
    const response = await hybrisAxiosClient.get('/carts/current/addresses/delivery/list');
    return response.data;
};

const getDeliveryAddress = async (): Promise<UserAddress> => {
    const response = await hybrisAxiosClient.get('/carts/current/address/delivery');
    return response.data;
};

const setDeliveryAddress = async (id: string): Promise<UserAddress> => {
    const response = await hybrisAxiosClient.put(
        `/carts/current/address/delivery?addressId=${encodeURIComponent(id)}`,
    );
    return response.data;
};

const removeDeliveryAddress = async (id: string): Promise<void> => {
    await hybrisAxiosClient.put(
        `/carts/current/address/delivery?addressId=${encodeURIComponent(id)}`,
    );
};

const getPostalCodeDeliveryOptions = async (
    postalCode: string,
): Promise<ApiStoreDeliveryOptions> => {
    const response = await hybrisAxiosClient.get(
        `/carts/current/deliveryOptions?postalCode=${postalCode}`,
    );
    return response.data;
};

const getDriverMessage = async (): Promise<string> => {
    const response = await hybrisAxiosClient.get<{
        driverMessage: string;
    }>('/carts/current/driverMessage');
    return response.data?.driverMessage;
};

const setDriverMessage = async (driverMessage: string): Promise<void> => {
    await hybrisAxiosClient.put('/carts/current/driverMessage', { driverMessage });
};

const listPaymentMethods = async (): Promise<ApiGetPaymentMethodsResponse> => {
    const response = await hybrisAxiosClient.get('carts/current/paymentmethods');
    return response.data;
};

const setPaymentMethod = async (code: PaymentCode): Promise<void> => {
    await hybrisAxiosClient.put(
        `carts/current/paymentmethod?paymentMethod=${encodeURIComponent(code)}`,
    );
};

// matkonto and kobe use the same init endpoint!?
const initializeMatkontoPayment = async (): Promise<ApiKobePayment> => {
    const response = await hybrisAxiosClient.get('carts/current/paymentmethod/init/matkonto');
    return response.data;
};
// matkonto and kobe use the same init endpoint!?
const initializeKobePayment = async (): Promise<ApiKobePayment> => {
    const response = await hybrisAxiosClient.get('carts/current/paymentmethod/init/kobe');
    return response.data;
};
const initializeIntrumPayment = async (): Promise<ApiIntrumPayment> => {
    const response = await hybrisAxiosClient.get('carts/current/paymentmethod/init/intrum');
    return response.data;
};
const initializeHmpPayment = async (): Promise<ApiHmpPayment> => {
    const response = await hybrisAxiosClient.get('carts/current/paymentmethod/init/hmp');
    return response.data;
};
const initializeEdiPayment = async (): Promise<ApiIntrumPayment> => {
    const response = await hybrisAxiosClient.get('carts/current/paymentmethod/init/edi');
    return response.data;
};

/** Cart should be validated before sent to punchout, i think we also could use this one for "normal orders" */
const validateCart = async ({
    userId,
    token,
}: {
    userId: string;
    token: string;
}): Promise<ApiValidationCartResponse> => {
    const response = await getDirectHybrisAxiosClient(token, [
        validateCartErrorInterceptor,
    ]).post<ApiValidationCartResponse>(`users/${userId}/carts/current/validation`);
    return response.data;
};

// samlingsfaktura uses the same endpoint as Intrum
const initializeSamlingsFakturaPayment = async () => {
    const response = await hybrisAxiosClient.get('carts/current/paymentmethod/init/intrum');
    return response.data;
};

const initializeKlarnaPayment = async (
    newSession: boolean,
    subscriptionFrequency: SubscriptionFrequency | null,
): Promise<string> => {
    const response = await hybrisAxiosClient.post(
        `carts/current/klarna?newSession=${newSession}&subscription=${!!subscriptionFrequency}&frequency=${
            subscriptionFrequency ? subscriptionFrequency.toString() : ''
        }`,
    );
    return response.data;
};

const editPayment = async (): Promise<ApiEditPaymentResponse> => {
    const response = await hybrisAxiosClient.put('carts/current/payment/edit');
    return response.data;
};

const getBillingAddress = async (): Promise<InvoiceAddress> => {
    const response = await hybrisAxiosClient.get('carts/current/address/billing');
    return response.data;
};

const setBillingAddress = async (addressId: string): Promise<InvoiceAddress> => {
    const response = await hybrisAxiosClient.put(
        `carts/current/address/billing?addressId=${encodeURIComponent(addressId)}`,
    );
    return response.data;
};

const removeBillingAddress = async (): Promise<InvoiceAddress> => {
    const response = await hybrisAxiosClient.delete('carts/current/address/billing');
    return response.data;
};

const setItemsReplaceability = async (arg: { replaceAll: boolean }): Promise<void> => {
    await hybrisAxiosClient.put('/carts/current/replaceAll', arg);
};

/* cart?fields=FULL does not return this field so we cannot just refetch cart :O 
but we can ask just for requisitionExpireTime from current cart, 
altho we should have this field either in fields=FULL always or a separate endpoint like with driver message
*/
const getRequisitionExpireTime = (token: string) => async (userId: string) => {
    const response = await getDirectHybrisAxiosClient(token).get<{ requisitionExpireTime: string }>(
        `users/${userId}/carts/current?fields=requisitionExpireTime`,
    );

    return response.data;
};

/* cart?fields=FULL does not return this field so we cannot just refetch cart :O 
but we can ask just for internalReference from current cart, 
altho we should have this field either in fields=FULL always or a separate endpoint like with driver message
*/
const getInternalReference = (token: string) => async (userId: string) => {
    const response = await getDirectHybrisAxiosClient(token).get<{ internalReference: string }>(
        `users/${userId}/carts/current?fields=internalReference`,
    );

    return response.data;
};

const setInternalReference =
    (token: string) => async (userId: string, internalReference: string) => {
        await getDirectHybrisAxiosClient(token).put(
            `users/${userId}/carts/current/punchout/internalreference`,
            {
                internalreference: internalReference,
            },
        );
    };

const getContactPhoneNumber = (token: string) => async (userId: string) => {
    const response = await getDirectHybrisAxiosClient(token).get<{ phone: string }>(
        `users/${userId}/carts/current/punchout/contact`,
    );

    return response.data;
};

const setContactPhoneNumber = (token: string) => async (userId: string, phone: string) => {
    await getDirectHybrisAxiosClient(token).put(`users/${userId}/carts/current/punchout/contact`, {
        phone,
    });
};

// not sure yet if we need this
const createPunchout = (token: string) => async (userId: string) => {
    const response = await getDirectHybrisAxiosClient(token).post<ApiCreatePunchoutReponse>(
        `users/${userId}/carts/current/punchout/requisition`,
    );

    return response.data;
};

export const validateAssortmentApiUrl = (userId: string) =>
    `users/${userId}/carts/current/entries/not-in-assortment`;

const validateAssortment = (token: string) => async (userId: string) => {
    const response = await getDirectHybrisAxiosClient(token).get<{
        orderEntries: HybrisCartEntry[];
    }>(validateAssortmentApiUrl(userId));

    return response.data;
};

const deliveryAddresses = {
    fetchAll: getDeliveryAddressList,
    select: setDeliveryAddress,
    remove: removeDeliveryAddress,
    current: getDeliveryAddress,
};

const billingAddress = {
    select: setBillingAddress,
    remove: removeBillingAddress,
    current: getBillingAddress,
};

const punchout = {
    getInternalReference,
    setInternalReference,
    create: createPunchout,
    getRequisitionExpireTime,
    validateCart,
    getContactPhoneNumber,
    setContactPhoneNumber,
};

const recipes = {
    buyMultipleRecipes,
    removeRecipe,
    substituteProduct,
    getProductSubstitutes,
    changeRecipePortions,
};

const voucher = {
    add: applyVoucher,
    remove: removeVoucher,
};

const driverMessage = {
    get: getDriverMessage,
    set: setDriverMessage,
};

const payment = {
    list: listPaymentMethods,
    set: setPaymentMethod,
    initializeMatkontoPayment,
    initializeKobePayment,
    initializeIntrumPayment,
    initializeHmpPayment,
    initializeEdiPayment,
    initializeKlarnaPayment,
    initializeSamlingsFakturaPayment,
    edit: editPayment,
};

const cartApi = {
    deliveryAddresses,
    recipes,
    changeCartItemVariant,
    fetchCart,
    fetchCartSimple,
    getPostalCodeDeliveryOptions,
    voucher,
    driverMessage,
    setItemsReplaceability,
    payment,
    billingAddress,
    emptyCart,
    punchout,
    validateAssortment,
};

export default cartApi;
